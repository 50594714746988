<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="d-flex flex-row align-center">
            <h1>{{this.singular}}: {{this.data.firstName}} {{this.data.lastName}}</h1>
            <v-btn class="ml-2" small color="info" v-if="isAllowed('user','u')" @click="isEditable = !isEditable">
              <span v-if="this.isEditable">Cancel</span>
              <span v-else>Edit</span>
            </v-btn>
            <v-btn v-if="isEditable" class="ml-2" small color="success" @click="update">Save</v-btn>
            <v-btn v-if="isEditable && isAllowed('user','d')" small class="ml-2" color="error" @click="openDeleteDialog">Delete</v-btn>
            <v-btn class="ml-2" small :disabled="!isEditable" v-if="isAllowed('user','updatePassword')" color='warning' @click="openResetPassDialog">Reset Password</v-btn>
            <v-btn class="ml-2" small color="warning" :disabled="!this.data.isLoggedIn" @click="this.forceLogout">Force Logout</v-btn>
            <v-switch class="ml-2" v-model="userLocked" @change="toggleUserLock" label="User Locked"></v-switch>
          </span>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-form :disabled="!isEditable">
      <v-row>
          <v-col>
            <v-text-field
                label="First Name"
                outlined
                v-model="data.firstName"
            />
            <v-text-field
                label="Last Name"
                outlined
                v-model="data.lastName"
            />
            <v-text-field
                label="Address"
                outlined
                v-model="data.address"
            />
            <v-text-field
                label="Default Print Node"
                outlined
                v-model="data.metadata.printNode"
            />
            <v-text-field
                label="Default Commission Rate (%)"
                outlined
                v-model="data.metadata.commission"
            />
            <v-select
                :items="accessTimes"
                label="Access Start Time"
                outlined
                v-model="data.metadata.accessStartTime"
            />
            <v-select
                :items="accessTimes"
                label="Access End Time"
                outlined
                v-model="data.metadata.accessEndTime"
            />
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="data.dateOfBirth"
                    label="Date of Birth"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="data.dateOfBirth"
              />
            </v-menu>
            <v-select
                item-text="name"
                item-value="id"
                :items="userTypes"
                label="User Type"
                outlined
                v-model="data.userTypeId"
            />
            <v-autocomplete
                :items="(this.getUser && (this.getUser.userTypeId===1 || this.getUser.userTypeId===2))?this.branches:this.getAllowedBranches"
                item-text="name"
                item-value="id"
                v-model="data.metadata.branchData.main"
                label="Main Branch"
                outlined
                clearable
                @change="updateRemBranches"
            />
            <v-autocomplete
                :items="remBranches"
                item-text="name"
                item-value="id"
                v-model="data.metadata.branchData.allowed"
                label="Allowed Branches"
                outlined
                chips
                deletable-chips
                clearable
                @click:clear="clearAllowedBranches"
                multiple
            />
          </v-col>
          <v-col>
            <span v-if="this.isEditable">
              <v-chip
                  v-for="device in registeredDevices" :key="device.id"
                  class="ma-2"
              >
                {{device.name}} - {{device.id}}
              </v-chip>
            </span>
            <v-textarea
                label="Printers"
                outlined
                v-model="data.metadata.printers"
            />
            <v-text-field
                label="Phone"
                outlined
                v-model="data.phone"
            />
            <v-text-field
                label="Email"
                outlined
                v-model="data.email"
            />
            <v-text-field
                label="Username"
                :disabled="true"
                outlined
                v-model="data.username"
            />
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="data.hireDate"
                    label="Hire Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="data.hireDate"
              />
            </v-menu>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="data.fireDate"
                    label="Fire Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="data.fireDate"
              />
            </v-menu>
            <v-textarea
                label="Secure ID Token"
                outlined
                v-model="data.metadata.sIdToken"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-title><h2>Timelock</h2></v-card-title>
              <v-card-text>
                <table v-if="data.metadata.timeLockArray">
                  <tr>
                    <td></td>
                    <td v-for="(hour, index) in data.metadata.timeLockArray[0]" :key="index" @click="toggleColumn(index)">
                      <v-chip small color=info>{{ index }}</v-chip>
                    </td>
                  </tr>
                  <tr v-for="(day, index2) in data.metadata.timeLockArray" :key="index2">
                    <td @click="toggleRow(index2)">
                      <v-chip small color=info>{{ weekday[index2] }}</v-chip>
                    </td>
                    <td v-for="(hour, index3) in day" :key="index3">
                      <v-chip small @click="changeTimeLockVal(index2,index3)" :color="hour == 1?'success':''">
                        {{ hour }}
                      </v-chip>
                    </td>
                  </tr>
                </table>
                <span class="d-flex flex-row my-4">
                  <v-btn class="mr-2 white--text" color="warning" @click="toggleTimeLockMultipleValue()">
                    <span v-if="timeLockMultiple == 1">Lock Allow</span>
                    <span v-if="timeLockMultiple == 0">Lock Block</span>
                  </v-btn>
                  <v-btn class="mr-2 white--text" color="teal" @click="timeLockSetAll(0)">Block All</v-btn>
                  <v-btn class="mr-2 white--text" color="teal" @click="timeLockSetAll(1)">Allow All</v-btn>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
          </v-col>
        </v-row>
      </v-form>
      <hr>
      <v-row class="d-flex flex-row">
        <v-col>
          <h2>Orders Created By {{data.firstName}}</h2>
          <v-data-table
            :headers="csrCreatedOrdersTableHeaders"
            :items="csrCreatedOrdersTableData"
            :sort-by.sync="csrOrderTableSettings.sortBy"
            :sort-desc.sync="csrOrderTableSettings.sortDesc"
            class="mt-5"
            @click:row="csrCreatedOrdersTableRowClick"
          >
            <template v-slot:item.createdAt="{ item }">
              <span>{{utils.formatDate(item.createdAt) }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{utils.parseOrderStatus(item.status)}}</span>
              <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{utils.parseOrderStatus(item.status)}}</span>
              <span v-if="item.status===1" class="v-btn success pa-1" style="font-size: 10px">{{utils.parseOrderStatus(item.status)}}</span>
            </template>
            <template v-slot:item.createdBy="{ item }">
              <span>{{lookupUsername(item.createdBy) }}</span>
            </template>
            <template v-slot:item.numItems="{ item }">
              <span>{{ item.numItems }}</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span>{{utils.formatCurrency(item.amount) }}</span>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-if="getGlobalValue('VEC_INCLUDES_AR')==='true'">
          <h2>Invoices Managed by {{data.firstName}}</h2>
          <v-data-table
            :headers="csrCommissionOrdersTableHeaders"
            :items="csrCommissionOrdersTableData"
            class="mt-5"
            @click:row="csrCommissionOrdersTableRowClick"
          >
          <template v-slot:item.createdAt="{ item }">
              <span>{{utils.formatDate(item.createdAt) }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{utils.parseOrderStatus(item.status)}}</span>
              <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{utils.parseOrderStatus(item.status)}}</span>
              <span v-if="item.status===1" class="v-btn success pa-1" style="font-size: 10px">{{utils.parseOrderStatus(item.status)}}</span>
            </template>
            <template v-slot:item.createdBy="{ item }">
              <span>{{lookupUsername(item.createdBy) }}</span>
            </template>
            <template v-slot:item.numItems="{ item }">
              <span>{{ item.numItems }}</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span>{{utils.formatCurrency(item.amount) }}</span>
            </template>
            <template v-slot:item.grandTotal="{ item }">
              <span>{{utils.formatCurrency(item.metadata.grandTotal)}}</span>
            </template>
            <template v-slot:item.paid="{ item }">
              <span>{{utils.formatCurrency((item.Transactions.reduce((acc,x)=>acc+parseInt(x.amount),0))) }}</span>
            </template>
            <template v-slot:item.balance="{ item }">
              <span>{{utils.formatCurrency(item.metadata.grandTotal-(item.Transactions.reduce((acc,x)=>acc+parseInt(x.amount),0))) }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="resetPassDialog.open"
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          Reset This Passsword?
        </v-card-title>
        <v-card-text>
          <v-text-field
              label="Admin Password"
              outlined
              v-model="resetPassDialog.adminPass"
              type="password"
          />
          <v-text-field
              :label="'New Password for '+ this.data.firstName"
              outlined
              v-model="resetPassDialog.newPass"
          />
          <v-btn @click="resetSecureIdToken()" color="warning">Reset Secure ID Token</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeResetPassDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="resetPass()"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog.isOpen" width="300px">
      <v-card>
        <v-card-title class="text-h5">
          Confirm Delete User
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeDeleteDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="deleteData"
          >
            Delete User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex"
  import utils from '../../plugins/helpers'
  import axios from 'axios';
  export default {
    plugins: {utils},
    data () {
      return {
        utils:utils,
        weekday: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
        timeLockMultiple: 1,
        deleteDialog: {
          isOpen: false,
        },
        userLocked: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        csrCreatedOrdersTableData: [],
        csrCreatedOrdersTableHeaders: [
          {text: 'Order #', align: 'start', value: 'id'},
          { text: 'Status', value: 'status' },
          { text: 'Date Created', value: 'createdAt' },
        ],
        csrCommissionOrdersTableData: [],
        csrCommissionOrdersTableHeaders: [
          {text: 'Order #', align: 'start', value: 'id'},
          { text: 'Status', value: 'status' },
          { text: 'Date Created', value: 'createdAt' },
          { text: 'Created By', value: 'createdBy' },
          { text: 'Grand Total', value: 'grandTotal' },
          { text: 'Amt. Paid', value: 'paid' },
          { text: 'Balance', value: 'balance' },
        ],
        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: {metadata: {sIdToken: '', printers:'', branchData: {}}},
        singular: "User",
        singularLower: "user",
        plural: "Users",
        pluralLower: "users",
        isEditable: false,
        userTypes: [],
        branches: [],
        remBranches: [],
        lastBranch: null,
        resetPassDialog: {
          open: false,
          adminPass: '',
          newPass: '',
        },

        csrOrderTableSettings: {
          sortBy: 'id',
          sortDesc: false
        },

        registeredDevices: [],
        accessTimes: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error;
        
          if(res.data.data.lockedBy && res.data.data.lockedAt){
            this.userLocked = true
          }

          if(!res.data.data.metadata){
            res.data.data.metadata = {sIdToken: '', branchData: {main:null,allowed:[]}}
          }
          if(res.data.data.dateOfBirth){
            res.data.data.dateOfBirth = new Date(res.data.data.dateOfBirth).toISOString().substring(0, 10);
          }
          if(res.data.data.hireDate){
            res.data.data.hireDate = new Date(res.data.data.hireDate).toISOString().substring(0, 10);
          }
          if(res.data.data.fireDate){
            res.data.data.fireDate = new Date(res.data.data.fireDate).toISOString().substring(0, 10);
          }
          if(!res.data.data.metadata.printers){
            res.data.data.metadata.printers = '{"allowed": "[]", "default": "[]"}'
          }else{
            res.data.data.metadata.printers = JSON.stringify(res.data.data.metadata.printers)
          }
          if(!res.data.data.metadata.branchData){
            res.data.data.metadata.branchData = {main:null,allowed:[]}
          }
          if(res.data.data.metadata.accessStartTime == null){
            res.data.data.metadata.accessStartTime = 7
          }
          if(res.data.data.metadata.accessEndTime == null){
            res.data.data.metadata.accessEndTime = 18
          }

          this.data = res.data.data;

          this.csrCreatedOrdersTableData = res.data.data.createdByUser
          this.csrCommissionOrdersTableData = res.data.data.csrUser

          let registeredDevices = await axios.get(`${this.getEndpoint}/api/devices`)
          if(registeredDevices.data.error) throw registeredDevices.data.error
          this.registeredDevices = registeredDevices.data.data.devices

          let uts = await axios.get(`${this.getEndpoint}/api/usertypes`);
          if(uts.data.error) throw uts.data.error;
          this.userTypes = uts.data.data;

          let branches = await axios.get(`${this.getEndpoint}/api/locations/branches`);
          if(branches.data.error) throw branches.data.error;
          this.branches = branches.data.data;

          this.updateRemBranches();


          // timelock feature where 0 = locked and 1 = unlocked
          let timeLockArray = []
          for(let i=0; i < 7; i++){
            let dayArr = []
            for(let j=0; j < 24; j++){
              dayArr.push(0)
            }
            timeLockArray.push(dayArr)
          }
          if(!this.data.metadata.timeLockArray){
            this.data.metadata.timeLockArray = timeLockArray //temp until it is loaded into system
          }

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getUser', 'getId', 'getEndpoint', 'isAllowed', 'getAllowedBranches', 'lookupUsername', 'getGlobalValue'])
    },
    methods: {
      ...mapMutations(['setUser']),
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      timeLockSetAll(val){
        console.log(val)
        for(let i = 0; i < this.data.metadata.timeLockArray.length; i++){
          this.toggleRow(i,val)
        }
      },
      toggleTimeLockMultipleValue(){
        if(this.timeLockMultiple === 1){
          this.timeLockMultiple = 0
        }else {
          this.timeLockMultiple = 1
        }
      },
      changeTimeLockVal(i,j){
        let x = this.data.metadata.timeLockArray[i][j]
        if(x===0){
          x = 1
        }else{
          x = 0
        }
        // this.data.metadata.timeLockArray[i][j] = x
        let newDay = JSON.parse(JSON.stringify(this.data.metadata.timeLockArray[i]))
        newDay[j] = x
        this.data.metadata.timeLockArray.splice(i,1,newDay)
        this.$forceUpdate()
        
      },
      toggleRow(i, val=null){
        if(val == null) val = this.timeLockMultiple
        let newDay = JSON.parse(JSON.stringify(this.data.metadata.timeLockArray[i]))
        for(let x=0; x < newDay.length; x++){
          newDay[x] = val
        }
        this.data.metadata.timeLockArray.splice(i,1,newDay)
        this.$forceUpdate()
      },
      toggleColumn(i){
        for(let row=0; row < this.data.metadata.timeLockArray.length; row++){
          let newDay = JSON.parse(JSON.stringify(this.data.metadata.timeLockArray[row]))
          newDay[i] = this.timeLockMultiple
          this.data.metadata.timeLockArray.splice(row,1,newDay)
          this.$forceUpdate()
        }
      },
      async update(){
        try {
          this.loader = true;
          this.data.metadata.printers = JSON.parse(this.data.metadata.printers)

          delete this.data.csrUser;
          delete this.data.createdByUser;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error

          if(res.data.success){
            this.snack(`${this.singular} info Updated 🎉`, "success");
            this.isEditable = false;
            if(this.data.id===this.getId){
              this.setUser(this.data)
            }
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.data.metadata.printers = JSON.stringify(this.data.metadata.printers)
          this.loader = false;
        }
      },
      async csrCreatedOrdersTableRowClick(row){
        try {
          await this.$router.push({ path: `/orders/view/${row.id}`})
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      async csrCommissionOrdersTableRowClick(row){
        try {
          await this.$router.push({ path: `/orders/view/${row.id}`})
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      openDeleteDialog(){
        this.deleteDialog.isOpen = true
      },
      closeDeleteDialog(){
        this.deleteDialog.isOpen = false
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)

          if(res.data.error) throw res.data.error

          this.snack(this.singular+" Deleted 🎉", "success");

          await this.$router.go(-1)
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      clearAllowedBranches(){
        this.data.metadata.branchData.allowed = [];
      },
      openResetPassDialog(){
        this.resetPassDialog.open = true;
      },
      closeResetPassDialog(){
        this.resetPassDialog.open = false;
        this.openResetPassDialog.adminPass = ''
        this.openResetPassDialog.newPass = ''
      },
      async resetPass(){
        try {
          let data = this.resetPassDialog
          data.adminId = this.getId
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/editPassword/${this.$route.params.id}`, data)
          if(res.data.error) throw res.data.error
          if(!res) throw "Error. Perhaps it is your password."

          if(res.data.success){
            this.snack("Password Updated", "success");
            this.closeResetPassDialog()
          }else throw "Password not updated"
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      //superadmin only
      async forceLogout(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/forceLogout/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack("User Logged Out 🎉", "success");
          this.data.isLoggedIn = false
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async toggleUserLock(){
        try {
          let x = this.userLocked?'lock':'unlock'
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${x}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data)
          this.snack(`User manually ${x}ed.`, "success");
        } catch (error) {
          console.error(error)
          this.userLocked = !this.userLocked
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async resetSecureIdToken(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/resetSecureIdToken/`, {uId: this.$route.params.id})
          if(res.data.error) throw res.data.error

          this.snack("SID Created", "success");
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      updateRemBranches(){
        if(this.data.metadata.branchData.main===null){
          this.remBranches = (this.getUser && (this.getUser.userTypeId===1 || this.getUser.userTypeId===2))?this.branches:this.getAllowedBranches;
        }
        else{
          let arr = [];

          for(let branch of this.branches){
            let obj = {
              name: branch.name,
              id: branch.id,
              disabled: this.data.metadata.branchData.main===branch.id
            }

            arr.push(obj);
          }
          this.remBranches = arr;
        }

        if(this.lastBranch) this.data.metadata.branchData.allowed = this.data.metadata.branchData.allowed.filter(b => {return b!==this.lastBranch})
        if(this.data.metadata.branchData.main){
          if(this.data.metadata.branchData.allowed.indexOf(this.data.metadata.branchData.main)<0) this.data.metadata.branchData.allowed.push(this.data.metadata.branchData.main);
        }
        this.lastBranch = this.data.metadata.branchData.main;
      }
    }
  }
</script>
